import React, { useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import Card from 'src/components/shared/Card';
import 'cleave.js/dist/addons/cleave-phone.sa';
import Modal from 'src/components/shared/Modal';
import ChangePassword from 'src/components/account/ChangePassword';
import DeleteProfile from 'src/components/account/DeleteProfile';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import useForm from 'src/hooks/useForm';
import { AxiosError } from 'axios';
import axiosInstance from 'src/helper/AxiosInstance';
import { useAlert } from 'src/hooks/alerts';
import { Icon } from '@iconify/react';
import { number } from 'yup';

export default function index() {
  const [passVisible, setPassVisible] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [cards, setCards] = useState([]);
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    (async () => {
      const { data } = await axiosInstance.get(`/v1/payments/cards`);
      console.log(data);
      if (data.length) {
        setCards(data);
      }
    })();
  }, []);

  const deleteCard = async (id: any) => {
    const { status, data } = await axiosInstance.delete(`/v1/payments/cards/${id}`);
    if (status === 200) {
      setCards(cards.filter((card: any) => card.card_id !== id));
    } else {
      console.log(data);
    }
  };
  return (
    <div className="p-8 pt-2">
      <div className="flex items-start gap-4 flex-col-reverse lg:flex-row">
        <div className="w-full lg:max-w-sm space-y-4">
          <Card>
            <div className="text-xl font-bold">Cards</div>
            <div className="grid grid-cols-1 py-2 gap-y-3 my-2">
              {cards.length ? (
                cards.map((card: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between"
                    >
                      {card.payment_method === 'Mastercard' ? (
                        <Icon
                          icon="logos:mastercard"
                          width="30"
                        />
                      ) : card.payment_method === 'Visa' ? (
                        <Icon
                          icon="logos:visa"
                          width="30"
                        />
                      ) : card.payment_method === 'American_express' ? (
                        <Icon
                          icon="simple-icons:americanexpress"
                          width="30"
                        />
                      ) : card.payment_method === 'Mada' ? (
                        <span className="text-blue-400">مدي</span>
                      ) : (
                        <span>{card.payment_method}</span>
                      )}
                      {card.first_eight + '********'}
                      <button
                        type="button"
                        className=" text-white bg-red-500 rounded-2xl px-2 py-0 "
                        onClick={() => deleteCard(card.card_id)}
                      >
                        -
                      </button>
                    </div>
                  );
                })
              ) : (
                <span className="text-red-500 text-sm w-full text-center my-3">No Saved Cards</span>
              )}
            </div>
          </Card>
          <Card className="space-y-4">
            <div className="space-y-2">
              <p className="text-xl font-bold">Change password</p>
              <p className="text-sm text-gray-500">
                You can permanently delete or temporarily freeze your account.
              </p>
            </div>
            <button
              className="btn-with-icon !p-3 bg-primary/10 !text-primary w-full !text-sm"
              onClick={() => setPassVisible(true)}
            >
              Change password
            </button>
          </Card>
          <Card className="space-y-4">
            <div className="space-y-2">
              <p className="text-xl font-bold">Delete account</p>
              <p className="text-sm text-gray-500">
                You can permanently delete or temporarily freeze your account.
              </p>
            </div>
            <button
              className="btn-with-icon !p-3 bg-red-500/10 !text-red-500 w-full !text-sm"
              onClick={() => setDeleteVisible(true)}
            >
              Delete account
            </button>
          </Card>
          {user.userType === 'admin' && (
            <Card className="space-y-4">
              <div className="space-y-2">
                <p className="text-xl font-bold">Ali Express Credentials</p>
                <p className="text-sm text-gray-500">
                  You can update your aliexpress credential from this button.
                </p>
              </div>
              <button
                className="btn-with-icon !p-3 bg-green-500 !text-white w-full !text-sm"
                onClick={() =>
                  window.open(
                    'https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://apis.autodrop.me/aliexpress_auth&client_id=501906',
                    // 'https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://manatee-gorgeous-deeply.ngrok-free.app/aliexpress_auth&client_id=34271827',
                    '_blank'
                  )
                }
              >
                Update
              </button>
            </Card>
          )}
        </div>
        <div className="space-y-4 w-full flex-1">
          <Card className="space-y-4">
            <p className="text-xl font-bold">Profile details</p>
            <UpdateProfile />
          </Card>
          <Card className="space-y-4">
            <div className="space-y-2">
              <p className="text-xl font-bold">Auto Drop</p>
              <p className="text-sm text-gray-500">
                AutoDrop is a website established in 2023 in order to facilitate and facilitate the
                e-commerce process for merchants, as it provides them with the service of automatic
                linking between their store and the famous sites and platforms that support
                dropshipping.
              </p>
            </div>
          </Card>
        </div>
      </div>

      <Modal
        visible={passVisible}
        handleClose={() => setPassVisible(false)}
        title="Change password"
      >
        <React.Fragment>
          <ChangePassword />
        </React.Fragment>
      </Modal>

      <Modal
        visible={deleteVisible}
        handleClose={() => setDeleteVisible(false)}
        title="Delete account"
      >
        <React.Fragment>
          <DeleteProfile />
        </React.Fragment>
      </Modal>
    </div>
  );
}

function UpdateProfile() {
  const alert = useAlert();
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>(user);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const [sync, setSync] = useState(user?.product_sync);
  console.log(user);
  async function UpdateProfileInfo() {
    try {
      setDisabled(true);
      setErrors(undefined);
      const { data } = await axiosInstance.post('auth/update-profile', {
        ...values,
        id: user.id,
        product_sync: sync
      });
      alert.show({ text: data?.message, visible: true });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error: AxiosError | any) {
      const err = error.response;
      if (error instanceof AxiosError) {
        console.log(err);
        setErrors(err.data.message);
      }
    } finally {
      setDisabled(false);
    }
  }

  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler: UpdateProfileInfo
  });
  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">Full name</label>
        <input
          type="text"
          placeholder="..."
          className="form-input"
          value={values.name}
          name="name"
          onChange={handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">E-mail address</label>
        <input
          type="email"
          placeholder="example@example.com"
          className="form-input"
          value={values.email}
          name="email"
          onChange={handleChange}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Phone number</label>
        <Cleave
          options={{
            phone: true,
            phoneRegionCode: 'sa'
          }}
          placeholder="966"
          className="form-input"
          value={values.mobile}
          name="mobile"
          onChange={handleChange}
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group ">
        <label className="form-label">Products Sync</label>
        <div className="flex-1 flex items-center  gap-x-5 mb-2">
          <span
            className={
              sync
                ? 'bg-red-200 px-3 py-2 rounded-lg text-white cursor-pointer'
                : `text-white bg-red-500 px-3 py-2 rounded-lg cursor-pointer`
            }
            onClick={() => setSync(false)}
          >
            Off
          </span>
          <span
            className={
              sync
                ? `text-white cursor-pointer bg-green-500 px-2 py-2 rounded-lg`
                : 'text-white bg-green-200 px-2 py-2 rounded-lg cursor-pointer'
            }
            onClick={() => setSync(true)}
          >
            ON
          </span>
        </div>
        <span className="text-sm block text-red-600 ">
          Note : When synchronization is disabled, the merchant is responsible for any losses he
          faces due to price changes
        </span>
      </div>
      <div>
        <button
          className="btn-with-icon !p-3 bg-secondary !text-sm"
          type="submit"
          disabled={disabled}
        >
          Save changes
        </button>
      </div>
    </form>
  );
}


